import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { CellLabel, EntityBubbleImage, EntityValuePillList, Icon, useLang, useLink } from '@shapeable/ui';
import { Deposit } from '@shapeable/copper-connect-types';
import { classNames, entityLabel, locationDMS } from '@shapeable/utils';
const cls = classNames('deposit-card');

import { filter } from 'lodash';
import { METALLOGENICA_PORTAL_DEPOSIT_URL } from '../../data';
import { LocationIcon, LocationIconGlyph } from '@shapeable/icons';
import { ORANGE } from '../../theme';

// -------- Types -------->

export type DepositCardProps = Classable & HasChildren & { 
  entity?: Deposit;
};

export const DepositCardDefaultProps: Omit<DepositCardProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
  `,
  tablet: css`
    font-size: 0.9em;
  `,
  desktop: css`
    font-size: 1em;
  `,
});

const LayoutStyles = breakpoints({
  base: css`
    display: flex;
    align-items: flex-start;
    gap: ${theme.UNIT(4)};
    align-self: stretch;
  `,
});


const ImageStyles = breakpoints({
  base: css`
    flex-shrink: 0;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    flex: 1 0 0;
    gap: ${theme.UNIT(2)};
    flex-grow: 1;
    flex-direction: column;
    padding-right: ${theme.UNIT(4)};
  `,
  tablet: css`
    gap: ${theme.UNIT(4)};
    flex-direction: row;
  `
});

const DetailsStyles = breakpoints({
  base: css`
    display: flex;
    max-width: 300px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
  `,
});

const CommodityInfoStyles = breakpoints({
  base: css`
    display: flex;
    padding-bottom: 0px;
    margin-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;

    a {
      color: ${theme.COLOR('dark')};
      text-decoration: none;

      &:hover {
        color: ${theme.COLOR('primary')};
      }
    }
  `,
});

const TypeStyles = breakpoints({
  base: css`
    color: var(--shapeable-text, #5A5A5A);
  `,
});

const LabelStyles = breakpoints({
  base: css`
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: ${28/16}em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
});


const FootStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  `,
});

const CountryStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: var(--shapeable-black, #000);
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.14px;
  `,
});

const LocationStyles = breakpoints({
  base: css`
    display: flex;
    height: 32px;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  `,
});

const IconStyles = breakpoints({
  base: css`
    
  `,
});

const CoordinatesStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: var(--shapeable-strong, #000);
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  `,
});

const ConnectionsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex: 1 0 0;
    align-self: stretch;
  `,
});

const CommoditiesStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
  `,
});

const CommodityTypeStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: var(--shapeable-text, #5A5A5A);
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.13px;
  `,
});

const CommodityListStyles = breakpoints({
  base: css`
    overflow: hidden;
    color: var(--shapeable-strong, #000);
    text-overflow: ellipsis;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `,
});





// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Layout: styled.div`${LayoutStyles}`,
        Image: styled(EntityBubbleImage)`${ImageStyles}`,
      Body: styled.div`${BodyStyles}`,
        Details: styled.div`${DetailsStyles}`,
          CommodityInfo: styled.div`${CommodityInfoStyles}}`,
            Type: styled(CellLabel)`${TypeStyles}`,
            Label: styled.span`${LabelStyles}`,
          Foot: styled.div`${FootStyles}`,
            Country: styled.span`${CountryStyles}`,
            Location: styled.span`${LocationStyles}`,
              Icon: styled(Icon)`${IconStyles}`,
              Coordinates: styled.span`${CoordinatesStyles}}`,
        Connections: styled.div`${ConnectionsStyles}`,
          Commodities: styled(EntityValuePillList)`${CommoditiesStyles}`,
          
            CommodityType: styled(CellLabel)`${CommodityTypeStyles}`,
            CommodityList: styled.span`${CommodityListStyles}`,
};

export const DepositCard: Shapeable.FC<DepositCardProps> = (props) => {
  const { className, children, entity } = props;

  const { name, commodities, openGraph, country, region } = entity;

  let type = entityLabel(entity).toUpperCase();

  const dms = locationDMS(entity);

  const t = useLang();

  const { Link } = useLink();

  const countryRegion = filter([country?.name, region?.name]).join(", ");

  const copperDeposits = commodities.filter(({ name }) => name === 'Copper');

  return (
   <My.Container className={cls.name(className)}>
    <My.Layout>
      <My.Image entity={entity.country} />  
      <My.Body>
        <My.Details>
          <My.CommodityInfo>
            <My.Type>{type}:</My.Type>
            <Link href={`${METALLOGENICA_PORTAL_DEPOSIT_URL}${entity.id}`} target='_blank'><My.Label>{name}</My.Label></Link>
          </My.CommodityInfo>
          <My.Foot>
            {
              countryRegion && <My.Country>{countryRegion}</My.Country>
            }
            <My.Location>
              <LocationIconGlyph size={22} color={ORANGE} />
              <My.Coordinates>{dms}</My.Coordinates>
            </My.Location>
          </My.Foot>
        </My.Details>
        <My.Connections>
          <My.CommodityType>{t('Commodity')}:</My.CommodityType>
            <My.Commodities variant='smallName' items={copperDeposits}>
            {/* {
              commodities.length && 
              <My.CommodityList>
              {

                commodities.map(({ name, symbol }) => `${name}${symbol && symbol !== name ? ` (${symbol})` : ''}`).join(", ")
              }
              </My.CommodityList>
            } */}
          </My.Commodities>
        </My.Connections>

      </My.Body>
    </My.Layout>
   {children}
   </My.Container>
  )
};

DepositCard.defaultProps = DepositCardDefaultProps;
DepositCard.cls = cls;